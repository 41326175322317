import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function WhatWeOffer() {
  return (
    <div className='what-we-offer'>
        
        <h2>What We Offer ?</h2>

        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className='carousel-div'>
                        <div className="number-section">
                            1
                        </div>
                        <div>
                            <h3>
                                Tailored Acquisition Strategies
                            </h3>
                            <p className='smaller'>
                                We don’t believe in one-size-fits-all. Our team crafts customised acquisition strategies that align with your brand’s goals, customer base, and industry standards. Whether it’s performance marketing, search engine optimization (SEO), or social media campaigns, we ensure that every touchpoint is optimised for success.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className='carousel-div'>
                        <div className="number-section">
                            2
                        </div>
                        <div>
                            <h3>
                                Data-Driven Decision Making
                            </h3>
                            <p className='smaller'>
                                Using the latest in data analytics and tracking tools, we continuously monitor and refine our strategies. Our approach ensures that every decision is backed by real-time data insights, improving conversion rates and lowering customer acquisition costs.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className='carousel-div'>
                        <div className="number-section">
                            3
                        </div>
                        <div>
                            <h3>
                                Multi-channel marketing for lead generation
                            </h3>
                            <p className='smaller'>
                                We create omnichannel experiences paid and organic media that engage your target audience where they are. From PPC advertising on leading platforms like Google Ads and Meta Advertising and social media platforms like Instagram and Youtube to email marketing and content strategies, we connect with your customers across every digital platform to maximise reach and ROI.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className='carousel-div'>
                        <div className="number-section">
                            4
                        </div>
                        <div>
                            <h3>
                                Lead Nurturing and Conversion
                            </h3>
                            <p className='smaller'>
                                Generating leads is only the first step in the customer acquisition process. We provide seamless solutions for nurturing and converting those leads into paying customers. With a focus on user experience, personalised communication, and automation, we guide potential clients through the entire sales funnel.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className='carousel-div'>
                        <div className="number-section">
                            5
                        </div>
                        <div>
                            <h3>
                                Performance Tracking & Optimization
                            </h3>
                            <p className='smaller'>
                                Our commitment continues till creating a profitable marketing investment. We provide ongoing management and optimization to ensure sustainable growth. With transparent reporting and continuous testing, we identify what works and fine-tune your campaigns for optimal performance to reduce the cost of leads and increase the conversion rate.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden"></span>
            </button>
            </div>

            
    </div>
  )
}

export default WhatWeOffer