import React from 'react'
import growthGraph from "../assets/images/growth-graph.webp";

function YourGrowth() {
  return (
    <section className='your-growth'>
        <div className="row">
            <div className="col-1">
                <h3>up to</h3>
                <h2><span>40%</span></h2>
                <h3>Increase in revenue growth</h3>
            </div>
            <div className="col-2">
                <h3>research</h3>
                <img src={growthGraph} alt="graph" />
                <h3>Increase in revenue growth</h3>
            </div>
        </div>
    </section>
  )
}

export default YourGrowth