import React from 'react';
import '../styles/aboutUs.css';

import XFactor from '../components/XFactor';
import YourGrowth from '../components/YourGrowth';
import ProgressSection from '../components/ProgressSection';
import Accordian from '../components/Accordian';
import Services from '../components/Services';
import FAQs from '../components/FAQs';

function AboutUs({menuHeight}) {


    
  return (
    <>
        <section className='container' style={{paddingTop : menuHeight + 50}}>
            <div className="header" style={{textAlign: "center"}}>
                <h2 > <span >Why Us</span> </h2>
                <h2>Because we’ve got the...</h2>
            </div>

            <XFactor  />

            <YourGrowth />


        </section>

        
        <ProgressSection />


        <section className='container'>
            <div className="header" style={{textAlign: "center"}}>
                <h2>More About US</h2>
            </div>
            

            <Accordian />

            <div className="header" style={{textAlign: "left", marginTop : "162px"}}>
                <h2 style={{marginBottom: "66px"}}>Services</h2>
            </div>

            <Services />


            <FAQs />

        </section>

    </>





  )
}

export default AboutUs