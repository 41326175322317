import React, { useState } from 'react';
import '../styles/Home5.css';

// Color images are as follows
import bst from "../assets/images/BSH.webp";
import rur from "../assets/images/RUR.webp";
import earth from "../assets/images/108-Earth-logo 1.webp";
import uscholar from "../assets/images/Uscolars.webp";
import bjs from "../assets/images/BJS.webp";
import micron from "../assets/images/Micron Logopng-01 1.webp";
import { Link } from 'react-router-dom';

//import './Home5.css'; // Assuming you have a CSS file named Home5.css for styling

function Home5() {
    const [hoveredImage, setHoveredImage] = useState(null);

    const handleMouseEnter = (image) => {
        setHoveredImage(image);
    };

    const handleMouseLeave = () => {
        setHoveredImage(null);
    };

    return (
        <div className='bgcolor'>
            <div className='container no-padding'>
                <div className='col-sm-12'>
                    <div className="row row-mobile">
                        <div className='col-sm-6 mtop'>
                            <div className="text-part" >
                                {/* <h1 className="st2">Ready to soar through enhancement?</h1>
                                <h1 className="st3">Reach out to us and let us know</h1> */}

                                <h1 className="st2">Join,</h1>
                                <h1 className="st3">The Wall of Success!</h1>

                                    <h2>

                                <Link to={'/contact'} className='book-now-btn'>Book a Consultation Now</Link>
                                    </h2>
                            </div>
                        </div>
                        <div className='col-sm-6 padt'>
                            <div className="col-sm-12 sizeadust">
                                <div className='row'>
                                    <div className="text-part d-2">

                                        <div className='box-shadow-3d bst'>
                                            <img src={bst} alt="bst" />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-6">
                                        <div className="text-part d-2">
                                            
                                        <div className='box-shadow-3d rur'>
                                            <img src={rur} alt="rur" />
                                        </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-part d-2">

                                            <div className='box-shadow-3d earth'>
                                                <img src={earth} alt="rur" />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                <div className="text-part d-2">

                                    <div className='box-shadow-3d uscholar'>
                                        <img src={uscholar} alt="uscholar" />
                                    </div>

                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-6">
                                    <div className="text-part d-2">

                                        <div className='box-shadow-3d bjs'>
                                            <img src={bjs} alt="bjs" />
                                        </div>


                                    </div>
                                    </div>
                                    <div className="col-sm-6">
                                    <div className="text-part d-2">

                                        <div className='box-shadow-3d micron'>
                                            <img src={micron} alt="micron" />
                                        </div>


                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home5;