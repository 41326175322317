import React from 'react'

function CustomerAcquisition({ data }) {

  return (
    <div className='customer-aqutsition' >
      <h2>{data.heading}</h2>
      <h3 dangerouslySetInnerHTML={{ __html: data.subHeading }} /> <br />
      <h3 style={{color : "#000", fontWeight : 600}}>{data.sussubHeading}</h3>
      <p className='smaller'>{data.para}</p><br />
      {
        data.paras
        ?
        <>
          <p className='smaller'>{data.paras}</p>
          <br />
          <br />
        </>
        :
        null
      }
      {
        data.subHeading2
        ?
        <>
          <br />
          <h3>{data.subHeading2}</h3>
        </>
        :
        null
      }
      {
        data.para2
        ?
        <>
          <p className='smaller'>{data.para2}</p>
          <br /> <br /> <br />
        </>
        :
        null
      }

      {
        data.div_heading
        ?
        <div className='divpart'>
          <h3>{data.div_heading}</h3> <br />
          <h4>{data.subdivheading1}</h4>
          <p className='smaller'>{data.divpara1}</p> <br />
          <h4>{data.subdivheading2}</h4>
          <p className='smaller'>{data.divpara2}</p> <br />
          <h4>{data.subdivheading3}</h4>
          <p className='smaller'>{data.divpara3}</p> <br />
          <h4>{data.subdivheading4}</h4>
          <p className='smaller'>{data.divpara4}</p> <br />
          <h4>{data.subdivheading5}</h4>
          <p className='smaller'>{data.divpara5}</p> <br />
      </div>
        :
        null
      }
      
      
      

    </div>
  )
}

export default CustomerAcquisition