import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PiechartDesign() {
    const chartRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout
    }));

    // ring for New Brand
    const series1 = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      radius: am5.percent(100),
      innerRadius: am5.percent(90)
    }));

    series1.data.setAll([
      { category: "New Brand", value: 100, color: am5.color(0x0031FF) }
    ]);

    series1.slices.template.setAll({
      tooltipText: "{category}",
      fill: am5.color(0x0031FF),
      stroke: am5.color(0x0031FF) // Remove the stroke outline
      
    });

    series1.slices.template.adapters.add("fill", (fill, target) => {
      return target.dataItem.dataContext.color;
    });

    series1.ticks.template.set("forceHidden", true);
    series1.labels.template.set("forceHidden", true);

    // ring for Go To Market
    const series2 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(90),
        innerRadius: am5.percent(80)
      }));
  
      series2.data.setAll([
        { category: "Go To Market", value: 100, color: am5.color(0x002AD8) }
      ]);
  
      series2.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x002AD8),
        stroke: am5.color(0x002AD8) // Remove the stroke outline
      });
  
      series2.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series2.ticks.template.set("forceHidden", true);
      series2.labels.template.set("forceHidden", true);

    // ring for Deep Stick Market
    const series3 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(80),
        innerRadius: am5.percent(70)
      }));
  
      series3.data.setAll([
        { category: "Deep Stick Market", value: 100, color: am5.color(0x0022B0) }
      ]);
  
      series3.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x0022B0),
        stroke: am5.color(0x0022B0) // Remove the stroke outline
      });
  
      series3.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series3.ticks.template.set("forceHidden", true);
      series3.labels.template.set("forceHidden", true);

      // ring for Media & Campaign
    const series4 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(70),
        innerRadius: am5.percent(60)
      }));
  
      series4.data.setAll([
        { category: "Media & Campaign", value: 100, color: am5.color(0x001B8C) }
      ]);
  
      series4.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x001B8C),
        stroke: am5.color(0x001B8C) // Remove the stroke outline

      });
  
      series4.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series4.ticks.template.set("forceHidden", true);
      series4.labels.template.set("forceHidden", true);

      // ring for Print, OOH, Mainline
    const series5 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(60),
        innerRadius: am5.percent(50)
      }));
  
      series5.data.setAll([
        { category: "Print, OOH, Mainline", value: 100, color: am5.color(0x001466) }
      ]);
  
      series5.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x001466),
        stroke: am5.color(0x001466) // Remove the stroke outline
      });
  
      series5.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series5.ticks.template.set("forceHidden", true);
      series5.labels.template.set("forceHidden", true);

      // ring for Film Production
    const series6 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(50),
        innerRadius: am5.percent(40)
      }));
  
      series6.data.setAll([
        { category: "Film Production", value: 100, color: am5.color(0x000C40) }
      ]);
  
      series6.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x000C40),
        stroke: am5.color(0x000C40) // Remove the stroke outline

      });
  
      series6.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series6.ticks.template.set("forceHidden", true);
      series6.labels.template.set("forceHidden", true);

      // ring for Copywriting & Original
    const series7 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(40),
        innerRadius: am5.percent(30)
      }));
  
      series7.data.setAll([
        { category: "Copywriting & Original", value: 100, color: am5.color(0x00051B) }
      ]);
  
      series7.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x00051B),
        stroke: am5.color(0x00051B) // Remove the stroke outline

      });
  
      series7.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series7.ticks.template.set("forceHidden", true);
      series7.labels.template.set("forceHidden", true);

      // ring for Creatives & Co-Laterals
    const series8 = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        radius: am5.percent(30),
        innerRadius: am5.percent(20)
      }));
  
      series8.data.setAll([
        { category: "Creatives & Co-Laterals", value: 100, color: am5.color(0x00030F) }
      ]);
  
      series8.slices.template.setAll({
        tooltipText: "{category}",
        fill: am5.color(0x00030F),
        stroke: am5.color(0x00030F) // Remove the stroke outline

      });
  
      series8.slices.template.adapters.add("fill", (fill, target) => {
        return target.dataItem.dataContext.color;
      });
  
      series8.ticks.template.set("forceHidden", true);
      series8.labels.template.set("forceHidden", true);


      // ring for Celebrity & Influencer
    const series9 = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      radius: am5.percent(20),
      innerRadius: am5.percent(0)
    }));

    series9.data.setAll([
      { category: "Celebrity & Influencer", value: 100, color: am5.color(0x000000) }
    ]);

    series9.slices.template.setAll({
      tooltipText: "{category}",
      fill: am5.color(0x000000),
      stroke: am5.color(0x000000) // Remove the stroke outline
      
    });

    series9.slices.template.adapters.add("fill", (fill, target) => {
      return target.dataItem.dataContext.color;
    });

    series9.ticks.template.set("forceHidden", true);
    series9.labels.template.set("forceHidden", true);

    // Disable the amCharts link
    root._logo.dispose();

    return () => {
      root.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
}

export default PiechartDesign