import React, { useRef } from 'react'
import CustomerAcquisition from '../components/CustomerAcquisition'
import '../styles/service.css'
import '../styles/piechart.css'
import GraphDetailedInfo from '../components/GraphDetailedInfo'
import PieChart from '../components/PieChart'
import CustomerAcquisitionBanner from '../components/CustomerAcquisitionBanner'
import whiteRaised from '../assets/images/white-raised.svg';
import backgroundImage from '../assets/images/tech-banner.png'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';


function Technology({menuHeight}) {

    const raisedSvg = useRef(null)
    const path1 = useRef(null)
    const path2 = useRef(null)

    const windiwWidth = window.innerWidth



    const Technology = {
        heading : "Technology",
        subHeading : "(Development) <sup>2<sup> ",
        sussubHeading : "Empowering Your Business with Innovative Digital Solutions",
        para : "Today technology drives growth and business is built on technology, businesses need robust, scalable, and efficient digital solutions to stay ahead of the curve. At Prodmax, we specialise in delivering cutting-edge technology and development services that are tailored to meet the unique needs of your business. From website development to AI automation, we build solutions that transform your operations, enhance customer engagement, and fuel long-term success.",
    }



    // Accordian Data Passing

    const EPR = {
        heading : "Our Expertise",
        para : `We offer a comprehensive range of technology and development services to help you streamline operations and scale your business:`,

        accordianList : [
            {
                id : 1,
                heading : "Website Development",
                para : `
                   We create fast, secure, and user-friendly websites that are optimised for performance, responsiveness, and search engine visibility. Whether you need a corporate website, portfolio, or landing page, our developers deliver solutions that meet modern web standards.    
                `
            },
            {
                id : 2,
                heading : "Ecommerce Solutions",
                para : `
                    Drive sales and enhance customer experiences with custom ecommerce platforms. We design and develop secure, scalable online stores with seamless checkout processes, inventory management, and payment gateway integrations.
                `
            },
            {
                id : 3,
                heading : "Mobile App Development",
                para : `
                    We build intuitive, high-performing mobile applications for iOS and Android. Whether it’s a native or cross-platform app, we design with user experience and functionality in mind, ensuring your app delivers real value to your audience.
                `
            },
            {
                id : 4,
                heading : "CRM Systems",
                para : `
                    Enhance your customer relationships with customised CRM solutions. Our CRM platforms are designed to streamline communication, automate sales processes, and provide insights that help you better understand and serve your customers.
                `
            },
            {
                id : 5,
                heading : "ERP Solutions",
                para : `Our ERP systems integrate your business processes into a single platform, improving efficiency and decision-making. From accounting to inventory management, we develop ERP solutions that drive operational excellence.`
            },
            {
                id : 6,
                heading : "LMS (Learning Management Systems)",
                para : `Whether you’re delivering internal training or external courses, our LMS platforms offer a comprehensive solution to manage and deliver educational content, track progress, and provide engaging learning experiences.`
            },
            {
                id : 7,
                heading : "Payment Gateway",
                para : `We work closely with leading payment gateways viz. Razorpay, Cashfree and many more. We consult businesses to choose the most suited for their business and help them integrate and manage it for seamless financial tech.`
            },
            {
                id : 8,
                heading : "Dashboards & Data Visualization",
                para : `Unlock the power of your data with customised dashboards and data visualisation tools. We help you transform complex data sets into actionable insights, enabling better decision-making across your organisation.`
            },
            {
                id : 9,
                heading : "AI & Automation",
                para : `
                    Harness the power of artificial intelligence and automation to optimise business processes, reduce manual tasks, and improve overall efficiency. From chatbot development to process automation, we deliver AI-driven solutions that give your business a competitive edge.
                `
            }
        ]
    }

    const CRM = {
        heading : "Our Approach",
        para : `At Prodmax, we take a holistic approach to development. Every solution we create is designed with your business goals and users in mind. Our process involves:`,
        openCard : true,

        accordianList : [
            {
                id : 1,
                heading : "Consultation",
                para : `
                    Understanding your business needs, goals, and challenges to recommend the best technological solutions.
                `
            },
            {
                id : 2,
                heading : "Planning & Strategy",
                para : `
                    Developing a detailed project roadmap that aligns with your objectives and timelines.
                `
            },
            {
                id : 3,
                heading : "Development & Testing",
                para : `
                    Our skilled developers bring the project to life, rigorously testing for functionality, security, and performance.
                `
            },
            {
                id : 4,
                heading : "Deployment & Support",
                para : `
                    After launch, we ensure a smooth transition and provide ongoing support to keep your systems running optimally.
                `
            },
        ]
    }

    const paymentGateway = { 
        heading : "Why Partner With Us?",
        para : ``,
        openCard : true,

        accordianList : [
            {
                id : 1,
                heading : "Tailored Solutions",
                para : `
                    We don’t believe in one-size-fits-all. Our solutions are customized to fit your business needs, ensuring maximum efficiency and scalability.
                `
            },
            {
                id : 2,
                heading : "Expert Team",
                para : `
                    Our team of developers, engineers, and technologists bring years of experience across various industries, ensuring top-tier technical expertise.
                `
            },
            {
                id : 3,
                heading : "Future-Proof Technology",
                para : `We stay ahead of the latest trends and innovations, ensuring that your business is equipped with technology that drives sustainable growth.`
            },
            {
                id : 4,
                heading : "End-to-End Support",
                para : `
                    From concept to execution and beyond, we offer continuous support to ensure the success of your digital solutions.
                `
            },
            
        ]
    }

    const ecommerce = {
        heading : "E-Commerce",
        para : `E-Commerce: Beyond Shopping Carts: Elevate Your Online Presence with Bespoke Ecommerce Development!

In today's fast-paced digital landscape, e-commerce has revolutionized the way businesses reach their customers. At PRODMAX, our "E-Commerce" solutions are designed to help you harness the power of online retail, reach a global audience, and drive business growth. We specialize in building, optimizing, and managing e-commerce platforms that provide seamless shopping experiences for your customers. Let's explore how our expertise can transform your retail presence in the digital world.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Essence of E-Commerce ",
                para : `
                    Customer Relationship Management (CRM) is the foundation of building and maintaining meaningful interactions with your customers. It's a strategy that integrates people, processes, and technology to foster long-term customer loyalty. At PRODMAX, we understand that CRM is the key to sustainable business success.

Our E-Commerce Solutions

Our E-Commerce solutions encompass a comprehensive approach, ensuring that your online store becomes a powerful sales engine:

1. E-Commerce Development:

Custom Online Store: We create a bespoke online store tailored to your brand, products, and target audience.

User-Friendly Design: Our designs prioritize user experience, making it easy for your customers to browse, select, and purchase products.

Mobile Optimization: We ensure your e-commerce platform is responsive and accessible across various devices, including smartphones and tablets.

2. Payment Gateway Integration:

Secure Transactions: We integrate secure payment gateways that provide peace of mind to both you and your customers.

Multiple Payment Options: We offer a range of payment methods to cater to diverse customer preferences.

Enhanced Security: Your customers' payment data is safeguarded through advanced encryption and compliance with industry standards.

3. Inventory and Order Management:

Real-Time Inventory Tracking: We implement systems that allow you to keep track of stock levels and restock as needed.

Order Processing: Streamlined order management ensures prompt order fulfillment and shipping.

Customer Engagement: Features like order status updates and support for returns build trust and loyalty.

4. SEO and Digital Marketing:

Visibility and Reach: We optimize your e-commerce platform for search engines to ensure potential customers can find your products easily.

Digital Advertising: We offer digital marketing services to help you reach your target audience and increase traffic to your online store.

Conversion Optimization: Our experts employ strategies to turn visitors into customers, increasing your sales.
                `
            },
            {
                id : 2,
                heading : "The Value of E-Commerce",
                para : `
                    Global Reach: E-commerce allows you to access a global customer base, expanding your market reach.

Convenience: Customers can shop at any time, providing a convenient and flexible shopping experience.

Convenience: Customers can shop at any time, providing a convenient and flexible shopping experience.

Cost-Effective: Operating an online store is often more cost-effective than maintaining a physical retail location.

Data-Driven Decisions: E-commerce platforms provide valuable data insights for making informed business decisions.
                `
            },
            {
                id : 3,
                heading : "Our commitment to your Digital Retail success",
                para : `
                    At PRODMAX, we are committed to your digital retail success through our E-Commerce solutions. We understand that each business has unique products, goals, and target audiences, and our approach is custom-tailored to align with your specific needs.
                `
            },
            {
                id : 4,
                heading : "Ready to transform your retail Presence ",
                para : `
                    Are you ready to harness the power of e-commerce, reach a global audience, and drive business growth? Contact us today to explore how our experts can help you build, optimize, and manage your e-commerce platform, providing a seamless shopping experience for your customers. Your digital retail success is our mission, and we're excited to transform your retail presence in the digital world.
                `
            },
            
            
        ]
    }



    const appDevelopment = {
        heading : "iOS & Android Apps",
        para : `iOS and Android App Development: From Idea to Interface. Transforming Concepts into Seamless Apps, Across Platforms.

In today's mobile-driven world, having a presence on both iOS and Android platforms is essential for businesses and entrepreneurs. At PRODMAX, our "iOS and Android App Development" services are designed to help you turn your app concept into a reality. We specialize in building high-quality, user-friendly, and feature-rich apps for both iOS and Android, enabling you to reach a broader audience and drive engagement. Let's explore how our expertise can transform your app vision into a digital masterpiece.
`,

        accordianList : [
            {
                id : 1,
                heading : "Significance of Ios and Android App Development",
                para : `
                    iOS and Android are the two dominant mobile platforms, collectively catering to billions of users worldwide. Building apps for both platforms allows you to tap into a vast and diverse audience. At PRODMAX, we understand that app development is the key to expanding your digital footprint.
                `
            },
            {
                id : 2,
                heading : "Our IOS and Android app Development Services",
                para : `
                    Our iOS and Android App Development services offer a comprehensive approach to building, launching, and maintaining your mobile applications:

1. Consultation and Strategy:

Needs Assessment: We collaborate with you to understand your app idea, business goals, and target audience.

Platform Selection: Based on your goals, we help you decide whether to launch on iOS, Android, or both.

Feature Planning: We assist in defining the core features and functionalities your app will offer.

2. Design and Development:

UI/UX Design: Our designers create an intuitive and visually appealing user interface, ensuring an engaging user experience.

Cross-Platform Development: We leverage technologies like React Native or Flutter to build apps that work seamlessly on both iOS and Android.

Coding and Testing: Our development team writes clean, efficient code and rigorously tests the app for performance and reliability.

3. App Launch:

App Store Submission: We guide you through the app submission process for the Apple App Store and Google Play Store.

Launch Strategy: Our experts assist in creating a marketing and launch strategy to maximize app visibility and downloads.

Feedback Integration: We help you gather and integrate user feedback to enhance the app post-launch.

4. Maintenance and Updates:

Regular Updates: We offer ongoing maintenance and updates to ensure your app remains compatible with the latest devices and operating systems.

Feature Enhancements: We can add new features and functionalities to keep your app competitive and user-friendly.

Security Measures: Regular security updates are applied to protect your app and user data.
                `
            },
            {
                id : 3,
                heading : "The value of IOS and Android app Development",
                para : `
                    Wider Audience Reach: Building for both platforms means your app can reach a broader audience, increasing its potential for success.

User Engagement: High-quality apps with user-friendly interfaces lead to better engagement and retention rates.

Brand Visibility: A well-designed app can enhance your brand's visibility and reputation.

Monetization Opportunities: Apps can open up new revenue streams through advertising, in-app purchases, and more.
                `
            },
            {
                id : 4,
                heading : "Our commitment to your Apps Success",
                para : `
                    At PRODMAX, we are dedicated to bringing your app vision to life through our iOS and Android App Development services. We understand that each app is unique, and our approach is custom-tailored to align with your specific objectives and target audience.
                `
            },
            {
                id : 5,
                heading : "Ready to Transform Your App Idea into Reality",
                para : `
                    Are you ready to turn your app concept into a reality, reaching a broader audience and driving engagement on both iOS and Android platforms? Contact us today to explore how our experts can help you design, develop, launch, and maintain your mobile applications. Your app's success is our mission, and we're excited to transform your app vision into a digital masterpiece.
                `
            },
            
            
        ]
    }

    const UIUX = {
        heading : "UI & UX",
        para : `UI & UX Design: From Pixels to Pleasure. Your Interface, Perfected With the Power of Intuitive Design!

In the digital realm, your user's first impression can make or break your online presence. At PRODMAX, our "UI & UX Design" services are dedicated to crafting exceptional user experiences that leave a lasting impact. We specialize in user interface (UI) and user experience (UX) design that not only captivates your audience but also ensures usability and accessibility. Let's explore how our expertise can transform your digital products into engaging, user-friendly, and visually stunning experiences.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Essence of UI & UX Design",
                para : `
                    User Interface (UI) and User Experience (UX) design are the cornerstones of creating user-friendly and aesthetically pleasing digital products. UI focuses on the visual aspects, including layout, buttons, and interactive elements, while UX encompasses the entire user journey, ensuring it is smooth, intuitive, and enjoyable. At PRODMAX, we understand that UI & UX design is the key to retaining and attracting users.
                `
            },
            {
                id : 2,
                heading : "Our UI & UX Design Services",
                para : `
                    Our UI & UX Design services offer a holistic approach to creating designs that engage, inform, and delight your audience:

1. User Research:

Understanding Your Audience: We conduct in-depth research to gain insights into your target audience, their preferences, and behavior.

Competitor Analysis: We assess your competitors and industry standards to identify opportunities and areas for differentiation.

2. User-Centered Design:

UI Design: Our UI designers create visually appealing interfaces with a focus on aesthetics, typography, color schemes, and visual elements.

UX Design: Our UX experts map out user journeys, wireframes, and prototypes to ensure a seamless and intuitive user experience.

3. Accessibility and Responsiveness:

Accessibility Compliance: We ensure that your digital products are accessible to all, including those with disabilities, by adhering to WCAG and ADA guidelines.

Responsiveness: Our designs adapt seamlessly to various screen sizes and devices, providing consistent experiences.

4. Testing and Iteration:

User Testing: We conduct user testing to gather feedback and make improvements to enhance the UI & UX.

Continuous Refinement: Iteration is a continuous process to refine and optimize the design for the best results.
                `
            },
            {
                id : 3,
                heading : "The value of UI & UX Design",
                para : `
                    Engagement: Exceptional UI & UX design captures and retains users' attention, leading to longer sessions and higher user retention.

Usability: User-friendly interfaces and smooth user experiences reduce bounce rates and boost customer satisfaction.

Conversion: Well-designed interfaces can improve conversion rates, driving users to take desired actions.

Accessibility: Inclusive design ensures that your digital products are accessible to all users, expanding your potential audience.
                `
            },
            {
                id : 4,
                heading : "Our Commitment to Exceptional User Experiences",
                para : `
                    At PRODMAX, we are committed to creating exceptional user experiences through our UI & UX Design services. We understand that each project is unique, and our approach is custom-tailored to align with your specific brand identity and user expectations.
                `
            },
            {
                id : 5,
                heading : "Ready to craft exceptional User Experiences ?",
                para : `
                    Are you ready to captivate your audience and ensure usability, accessibility, and user satisfaction through our UI & UX design services? Contact us today to explore how our experts can help you create engaging, user-friendly, and visually stunning digital products. Exceptional user experiences are our mission, and we're excited to transform your digital presence into a memorable journey for your users.
                `
            },
            
            
        ]
    }

    const automation = {
        heading : "Automation",
        para : `Automation: Automate the Ordinary, Elevate the Extraordinary. Ignite Progress with Our Automation Alchemy.

In the age of technology, automation has emerged as a game-changer for businesses, offering a path to streamline operations, reduce manual tasks, and boost efficiency. At PRODMAX, our "Automation" services are designed to help you harness the power of automation, allowing you to focus on what truly matters while repetitive processes are handled seamlessly. We specialize in implementing automation solutions that optimize your business processes and increase productivity. Let's explore how our expertise can transform your operations.
`,

        accordianList : [
            {
                id : 1,
                heading : "Significance of Automation",
                para : `
                    Automation involves the use of technology and software to perform repetitive tasks with minimal human intervention. Whether it's data entry, customer communication, or inventory management, automation can save time and resources while reducing the risk of human error. At PRODMAX, we understand that automation is the key to operational efficiency and growth.
                `
            },
            {
                id : 2,
                heading : "Our Automation Services",
                para : `
                    Our Automation services offer a comprehensive approach to streamline and enhance your business operations:

1. Process Assessment:

Understanding Your Needs: We conduct a thorough analysis of your business processes to identify areas where automation can be most beneficial.

Goals Definition: We work with you to define clear objectives and desired outcomes for the automation process.

2. Software Selection and Integration:

Selecting the Right Tools: We help you choose the most suitable automation software or tools that align with your business needs and goals.

Integration: Our team seamlessly integrates the chosen automation solutions into your existing systems and processes.

3. Customization and Workflow Design:

Tailored Automation: We customize automation workflows to fit your specific business requirements, ensuring that they enhance your processes.

User-Friendly Interface: We design interfaces that make it easy for your team to manage and monitor automated processes.

4. Monitoring and Optimization:

Real-Time Tracking: We provide tools to monitor the performance of automated processes in real time.

Continuous Improvement: Regular assessments and optimizations ensure that automation continues to meet your evolving needs.
                `
            },
            {
                id : 3,
                heading : "The Value of Automation",
                para : `
                    Efficiency: Automation streamlines operations, reducing manual tasks and saving time and resources.

Error Reduction: Automation minimizes the risk of human error, leading to higher data accuracy.

Consistency: Automated processes ensure that tasks are performed consistently and reliably.

Scalability: Automation solutions can grow with your business, adapting to increased demands.
                `
            },
            {
                id : 4,
                heading : "Our commitment to Operational Efficiency",
                para : `
                    At PRODMAX, we are committed to streamlining your operations, reducing manual tasks, and boosting efficiency through our Automation services. We understand that each business has unique processes and objectives, and our approach is custom-tailored to align with your specific needs.
                `
            },
            {
                id : 5,
                heading : "Ready to Streamline your Operations ?",
                para : `
                    Are you ready to streamline your operations, boost efficiency, and save valuable time and resources through automation? Contact us today to explore how our experts can help you implement automation solutions that optimize your business processes. Operational efficiency is our mission, and we're excited to transform your operations with automation.
                `
            },
            
            
        ]
    }

    const security = {
        heading : "Security",
        para : `In the era of digital transformation, the protection of your digital assets is paramount. At PRODMAX, our "Security" services are designed to provide comprehensive solutions for safeguarding your data, systems, and operations. We specialize in ensuring that your business remains resilient against threats, vulnerabilities, and cyberattacks. Let's explore how our expertise can fortify your security measures and protect your digital assets.
`,

        accordianList : [
            {
                id : 1,
                heading : "The Significance of Security",
                para : `
                    In an interconnected world, businesses face a wide range of security threats, including cyberattacks, data breaches, and unauthorized access. Security measures are essential to protect your digital assets, maintain the trust of your customers, and ensure the continuity of your operations. At PRODMAX, we understand that security is the foundation of your business's success.
                `
            },
            {
                id : 2,
                heading : "Our Security Services",
                para : `
                    Our Security services encompass a comprehensive approach to fortify your defenses and protect your digital assets:

1. Security Assessment and Auditing:

Vulnerability Assessment: We identify potential vulnerabilities in your systems, applications, and networks.

Penetration Testing: Our experts simulate cyberattacks to test the resilience of your security measures.

Compliance Auditing: We help you ensure that your business complies with industry and regulatory security standards.

2. Threat Detection and Response:

Intrusion Detection: We implement tools and technologies to detect unauthorized access and potential security breaches.

Incident Response: In the event of a security incident, we provide a rapid response to minimize damage and prevent future attacks.

Security Monitoring: We continuously monitor your systems for signs of malicious activities.

3. Security Solutions Implementation:

Firewalls and Antivirus: We set up firewalls and antivirus software to protect your systems from external threats.

Encryption: Data encryption ensures that sensitive information remains secure, even if it is intercepted.

Authentication and Access Control: We implement multi-factor authentication and access control systems to restrict unauthorized access.

4. Employee Training and Awareness:

Security Training: We offer training programs to educate your employees about security best practices.

Awareness Campaigns: Regular campaigns and updates keep your staff informed about the latest security threats.
                `
            },
            {
                id : 3,
                heading : "The value of Security",
                para : `
                    Data Protection: Security measures safeguard your sensitive data, including customer information and business-critical data.

Trust and Reputation: A strong commitment to security builds trust and enhances your reputation among customers and partners.

Compliance: Security measures help you comply with industry and regulatory standards, avoiding potential legal issues.

Business Continuity: Robust security ensures that your business operations continue without disruption.
                `
            },
            {
                id : 4,
                heading : "Our Commitment to your Security",
                para : `
                    At PRODMAX, we are committed to safeguarding your digital assets and protecting your business against threats and vulnerabilities through our Security services. We understand that each business has unique security needs, and our approach is custom-tailored to align with your specific objectives.
                `
            },
            {
                id : 5,
                heading : "Ready to Safeguard your Digital Assets ?",
                para : `
                    Are you ready to fortify your security defenses, protect your digital assets, and ensure the continuity of your business operations? Contact us today to explore how our experts can help you implement comprehensive security measures. Security is our mission, and we're excited to fortify your business against threats and vulnerabilities.
                `
            },
            
            
        ]
    }

    useGSAP(() => {
        gsap.from(raisedSvg.current, {
            translateY : (windiwWidth > 990) ? "-86vh" : ( windiwWidth > 600) ? "-50vh" : "-55vh",
            translateX : (windiwWidth > 990) ? "34.5vw" : ( windiwWidth > 600) ? "-25vw" : "-14vw",

        scrollTrigger : { 
            scroller : 'body',
            start : 'top 0%',
            end :  (windiwWidth > 990) ? "top -100%" : ( windiwWidth > 600) ? "top -50%" : "top -50%",
            scrub : 2,                
        },
        })


        gsap.from(path1.current , {
            attr : {
                fill : "#fff"
            },
            
            scrollTrigger : { 
                scroller : 'body',
                start : 'top 0%',
                end : "top -100%",
                scrub : 2,                
            },

        })

        gsap.from(path2.current , {
            attr : {
                fill : "#fff"
            },
            scrollTrigger : { 
                scroller : 'body',
                start : 'top 0%',
                end : "top -100%",
                scrub : 2,                
            },
        })

        

    })


  return (
    <>
        <CustomerAcquisitionBanner heading={"Technology"} image={whiteRaised} backgroundImage={backgroundImage}  />

    <div className="container">
        <section className='service' style={{paddingTop : (windiwWidth > 990)? menuHeight + 50 : null}}>   
            <svg viewBox="0 0 184 189" 
                fill="none" 
                className='svg'
                width={151.6}
                height={155.6}
                ref={raisedSvg}>
            <path ref={path1} d="M20.056 107.984C13.912 102.16 9.048 94.48 5.464 84.944C1.944 75.408 0.184 65.104 0.184 54.032C0.184 42.96 1.944 32.656 5.464 23.12C9.048 13.584 13.912 5.904 20.056 0.0799961H40.6V2.096C34.392 8.624 29.496 16.496 25.912 25.712C22.328 34.864 20.536 44.304 20.536 54.032C20.536 63.824 22.328 73.328 25.912 82.544C29.496 91.696 34.392 99.536 40.6 106.064V107.984H20.056ZM111.035 107.984V106.064C117.244 99.536 122.14 91.696 125.724 82.544C129.308 73.328 131.1 63.824 131.1 54.032C131.1 44.304 129.308 34.864 125.724 25.712C122.14 16.496 117.244 8.624 111.035 2.096V0.0799961H131.58C137.724 5.904 142.556 13.584 146.076 23.12C149.66 32.656 151.452 42.96 151.452 54.032C151.452 65.104 149.66 75.408 146.076 84.944C142.556 94.48 137.724 102.16 131.58 107.984H111.035Z" fill="#000"/>

            <path ref={path2} d="M62.9575 70.448H41.8375L65.4535 17.744H86.3815L109.806 70.448H88.6855L75.9175 41.84L62.9575 70.448Z" fill="#0031FF"/>
            </svg>

            <CustomerAcquisition data={Technology} />
        </section>

        <div className="piechart">
            <div className="legends desktop-legends">
                <div className="legend">
                    <div className="box box-1"></div>
                    <h3>ERP Consultation</h3>
                </div>

                <div className="legend">
                    <div className="box box-2"></div>
                    <h3>CRM Consultation</h3>
                </div>

                <div className="legend">
                    <div className="box box-3"></div>
                    <h3>Payment Gateway Integration & Maintenance</h3>
                </div>

                <div className="legend">
                    <div className="box box-4"></div>
                    <h3>e-Commerce</h3>
                </div>
            </div>

            <PieChart />

            <div className="legends desktop-legends">
                <div className="legend">
                    <div className="box box-5"></div>
                    <h3>iOS & Android Apps</h3>
                </div>

                <div className="legend">
                    <div className="box box-6"></div>
                    <h3>UI & UX</h3>
                </div>

                <div className="legend">
                    <div className="box box-7"></div>
                    <h3>Automation</h3>
                </div>

                <div className="legend">
                    <div className="box box-8"></div>
                    <h3>Security</h3>
                </div>
            </div>



            <div className="legends mobile-legends">
                <div className="legend">
                    <div className="box box-1"></div>
                    <h3>ERP Consultation</h3>
                </div>

                <div className="legend">
                    <div className="box box-2"></div>
                    <h3>CRM Consultation</h3>
                </div>

                <div className="legend">
                    <div className="box box-3"></div>
                    <h3>Payment Gateway Integration & Maintenance</h3>
                </div>

                <div className="legend">
                    <div className="box box-4"></div>
                    <h3>e-Commerce</h3>
                </div>

                <div className="legend">
                    <div className="box box-5"></div>
                    <h3>iOS & Android Apps</h3>
                </div>

                <div className="legend">
                    <div className="box box-6"></div>
                    <h3>UI & UX</h3>
                </div>

                <div className="legend">
                    <div className="box box-7"></div>
                    <h3>Automation</h3>
                </div>

                <div className="legend">
                    <div className="box box-8"></div>
                    <h3>Security</h3>
                </div>
            </div>

        </div>
</div>
        <GraphDetailedInfo mode={"dark"} dataObj={EPR} />
        <GraphDetailedInfo mode={"light"} dataObj={CRM} />
        <GraphDetailedInfo mode={"dark"} dataObj={paymentGateway} />
        {/* <GraphDetailedInfo mode={"light"} dataObj={ecommerce} />
        <GraphDetailedInfo mode={"dark"} dataObj={appDevelopment} />
        <GraphDetailedInfo mode={"light"} dataObj={UIUX} />
        <GraphDetailedInfo mode={"dark"} dataObj={automation} />
        <GraphDetailedInfo mode={"light"} dataObj={security} /> */}
    </>

        
  )
}

export default Technology