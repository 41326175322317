import React from 'react'


function CustomerAcquisitionBanner({heading, image, backgroundImage}) {
  return (
    <div className='banner-services' style={{backgroundImage : `url(${backgroundImage})`}}>
        <div className="banner-info">
            <img src={image} alt="white-plus" className='banner-white-logo' />
            <h1>{heading}</h1>
        </div>
    </div>
  )
}

export default CustomerAcquisitionBanner