import React from 'react'
import pune from '../assets/images/pune icon-12 1.webp';
import mumbai from '../assets/images/mumbai-12 1.webp';
import chennai from '../assets/images/chennai.webp';
import mailImage from '../assets/images/email (1).webp';
import phoneImage from '../assets/images/phone-call (1).webp';

function ContactInfoMobile() {
  return (
    <div className='contact-info'>

        <div className="container">

            <h3 style={{fontSize: "28px"}}>Contact Us</h3>


            <div className="Email-container">

                <div className="email">  
                    <a href="mailto:info@prodmax.in">
                        <img src={mailImage} alt="email" />
                    </a>              
                    <a href="mailto:info@prodmax.in"><h3>info@prodmax.in</h3></a>
                </div>

                <div className="email">                
                    <a href="tel:+91 9967636592">
                        <img src={phoneImage} alt="email" />
                    </a>
                    <a href="tel:+91 9967636592">
                        <h3>+91 9967636592</h3>
                    </a>
                </div>
                
            </div>

            <div className="info-container">

                <div className="info">                
                    <img src={pune} alt="city-pune" />
                    <h3>Pune</h3>
                </div>

                <div className="info">                
                    <img src={mumbai} alt="city-mumbai" />
                    <h3>Mumbai</h3>
                </div>

                <div className="info">                
                    <img src={chennai} alt="city-chennai" />
                    <h3>Chennai</h3>
                </div>
            </div>

            
        </div>


    </div>
  )
}

export default ContactInfoMobile