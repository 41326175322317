import React, { useState } from 'react'
import addImage from '../assets/images/add-card.webp'
import addImageWhite from '../assets/images/add-card-white.webp'
import rasedTo from '../assets/images/raised-to.webp'
import rasedToWhite from '../assets/images/raised-to-white.webp'
import equalsTo from '../assets/images/equals.webp'
import equalsToWhite from '../assets/images/equals-white.webp'
import arrow1 from '../assets/images/arrow-1.webp'
import { Link } from 'react-router-dom'

function Services() {

    const [changeImgPlus, setChangeImagePlus] = useState(false)
    const [changeImgRaisedto, setChangeImageRaisedto] = useState(false)
    const [changeImgEquals, setChangeImageEquals] = useState(false)

    function handleChangeImagePlus(){
        setChangeImagePlus(prev => !prev)
    }

    function handleChangeImageRaisedto(){
        setChangeImageRaisedto(prev => !prev)
    }

    function handleChangeImageEquals(){
        setChangeImageEquals(prev => !prev)
    }


  return (
  <div className="container serv" >
    <section className='service-container'>
        <Link to={"/acquisition"} className="service-card" onMouseEnter={handleChangeImagePlus} onMouseLeave={handleChangeImagePlus}>
            <div className="image-container" >
                <img src={changeImgPlus ? addImageWhite : addImage} alt="add"  />
            </div>
            <div className="info">
                <h3 style={{fontWeight: 600}}>Customer Acquisition</h3>
                <p>Marketing <span>+</span> Sales</p>
            </div>

            <div className="arrow-image-container">
                <img src={arrow1} alt="Arrow" />
            </div>
        </Link>

        <Link to={"/technology"} className="service-card" onMouseEnter={handleChangeImageRaisedto} onMouseLeave={handleChangeImageRaisedto}>
            <div className="image-container">
                <img src={changeImgRaisedto ? rasedToWhite :rasedTo} alt="rasedTo" />
            </div>
            <div className="info">
                <h3 style={{fontWeight: 600}}>Technology</h3>
                <p><span>&#40;</span>Development<span>&#41;</span> <sup>2</sup></p>
            </div>

            <div className="arrow-image-container">
                <img src={arrow1} alt="Arrow" />
            </div>
        </Link>

        <Link to={"/design"} className="service-card" onMouseEnter={handleChangeImageEquals} onMouseLeave={handleChangeImageEquals}>
            <div className="image-container">
                <img src={changeImgEquals ? equalsToWhite : equalsTo} alt="add" />
            </div>
            <div className="info">
                <h3 style={{fontWeight: 600}}>Design</h3>
                <p>Web Design <span>+</span> Logo Design 
                <span>=</span> Brand Kit  Sales</p>
            </div>

            <div className="arrow-image-container">
                <img src={arrow1} alt="Arrow" />
            </div>
        </Link>
    </section></div>
  )
}

export default Services