import React, { useEffect, useRef, useState } from 'react';
import XBlue from "../assets/images/prodmax-X.webp";
import XWhite from "../assets/images/prodmax-X-white.webp";


function XFactor() {

    const [changeImg, setChangeImage] = useState(false)

    const cardRefs = useRef([]);
    const [activeCards, setActiveCards] = useState([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveCards(prevState => [...prevState, entry.target]);
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        cardRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => {
            cardRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    function handleChangeImage(){
        setChangeImage(prev => !prev)
    }


  return (
    <section className="x-factor">
            <div className="row row-1">

                    <div ref={(el) => (cardRefs.current[0] = el)} className={`card ${activeCards.includes(cardRefs.current[0]) ? 'active' : ''}`}>
                        <h2><span>X</span>pansion</h2>
                        <p className="smaller">
                        Unlocking growth opportunities is our specialty. Let us elevate your brand through strategic expansion initiatives tailored to maximize your market reach and potential.
                        </p>
                    </div>

                    <div ref={(el) => (cardRefs.current[1] = el)} className={`card ${activeCards.includes(cardRefs.current[0]) ? 'active' : ''}`}>
                        <h2><span>X</span>ecution</h2>
                        <p className="smaller">
                        Executing growth strategies is our forte. Partner with us to seamlessly implement expansion plans, driving tangible results and propelling your brand to new heights.
                        </p>
                    </div>
            </div>

            <div className="row row-2" >
                <div 
                    ref={(el) => (cardRefs.current[2] = el)} 
                    className={`card ${activeCards.includes(cardRefs.current[0]) ? 'active' : ''}`} 
                    onMouseEnter={handleChangeImage} 
                    onMouseLeave={handleChangeImage}
                >
                    <div className='card-inner'>
                        <h2><span>X</span>perience</h2>
                        <p className="smaller">
                        Embark on an experiential journey with us as we craft bespoke expansion strategies tailored to your brand. Let us curate an unforgettable experience of growth and achievement together.
                        </p>
                    </div>
                    <img 
                        src={changeImg ? XWhite  : XBlue } 
                        className='x-factor-image'
                        alt='logo-X' 
                    />
                </div>
            </div>

            <div className="row row-3">
            <div ref={(el) => (cardRefs.current[3] = el)} className={`card ${activeCards.includes(cardRefs.current[0]) ? 'active' : ''}`}>
                        <h2><span>X</span>posure</h2>
                        <p className="smaller">
                        Dive into an immersive journey where we not only strategize for growth but also amplify your brand's exposure. Experience firsthand how our tailored expansion initiatives elevate your brand's visibility and impact.
                        </p>
                    </div>

                    <h2 className='factor'><span>Factor</span></h2>

                    <div 
                        ref={(el) => (cardRefs.current[4] = el)} className={`card ${activeCards.includes(cardRefs.current[0]) ? 'active' : ''}`}
                    >
                        <h2><span>X</span>pertise</h2>
                        <p className="smaller">
                        Explore the realm of expansion with our seasoned team of experts at the helm. Harnessing their collective proficiency, we navigate your brand through tailored strategies, ensuring every move is backed by knowledge and skill.
                        </p>
                    </div>
            </div>
        </section>
  )
}

export default XFactor