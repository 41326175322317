import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ClipLoader from "react-spinners/ClipLoader";


function ContactFormMobile() {

    //  Field values
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [loader, setLoader] = useState(false);
    
    const [companyName, setCompanyName] = useState('');
    const [msg, setMsg] = useState('');

    const [sendBtnActive, setSendButtonActive] = useState(false);
    const [warningActive, setWarningActive] = useState(false);
    const [formSubmitSucessMsg, setFormSubmitSucessMsg] = useState(false);
    const [formSubmitErrorMsg, setFormSubmitErrorMsg] = useState(false);
    
    // Field Validations Styles
    const [nameInvalid, setNameInvalid] = useState("gray");
    const [phoneInvalid, setPhoneInvalid] = useState("gray");
    const [emailInvalid, setEmailInvalid] = useState("gray");
    const [companyNameInvalid, setCompanyNameInvalid] = useState("gray");
    const [msgInvalid, setMsgInvalid] = useState("gray");

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiToken = process.env.REACT_APP_API_TOKEN;

    const headers = {
        'Content-Transfer-Encoding': 'application/json',
        'Content-Type': 'multipart/form-data',
        'token': `${apiToken}`,
        'Access-Control-Allow-Origin': '*',
    }



  // Handle name change
  const handleNameChange = (e) => {
    const value = e.target.value;

    if(value.length === 0){
        setNameInvalid("red")
    }else{
        setNameInvalid("green")
    }


    if (/^[a-zA-Z\s]*$/.test(value)) {
      setName(value);
    }

  };

  // Handle phone number change
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if(value.length !== 10){
        setPhoneInvalid("red")
    }else{
        setPhoneInvalid("green")
    }

    if (/^\d*$/.test(value)) {
      setPhone(value);
    }
  };

  // Handle e-mail change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
        setEmailInvalid("green")
    }else{
        setEmailInvalid("red")
    }

    
  };

  // Handle Company name change
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);

    if(value){
        setCompanyNameInvalid("green")
    }else{
        setCompanyNameInvalid("red")
    }
    
  };

  // Handle Message change
  const handleMsgChange = (e) => {
    const value = e.target.value;
    setMsg(value);

    if(value){
        setMsgInvalid("green")
    }else{
        setMsgInvalid("red")
    }
    
  };


//   Chnage Button Bg-color when Form is Validated
  useEffect(() => {
    if (name && phone.length === 10 && email && companyName && msg) {
        if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)){
            setSendButtonActive(true);
        }else{
            setSendButtonActive(false);
        }
    } else {
        setSendButtonActive(false);
    }

    setWarningActive(false)
  }, [name, phone, email,companyName,msg]);

  

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name === "" || phone === "" || email === "" || companyName === "" || msg === "" ){

        if(name === ""){
            setNameInvalid("red");
            setWarningActive(true)
        }
        
        if(phone === ""){
            setPhoneInvalid("red");
            setWarningActive(true)
        }

        if(email === ""){
            setEmailInvalid("red");
            setWarningActive(true)
        }

        if(companyName === ""){
            setCompanyNameInvalid("red");
            setWarningActive(true)
        }

        if(msg === ""){
            setMsgInvalid("red");
            setWarningActive(true)
        }

        

    }else{

        if(sendBtnActive){
            // Add form submission logic here

            const data = {
                "name":name,
                "phone-no":phone,
                "email":email,
                "company_name":companyName,
                "message": msg
            }

            setLoader(true);

            axios.post(`${apiUrl}`,data, {
                headers : headers
              })
              .then(res => {
                if(res.data.status == "success"){
                    setName('')
                    setPhone('')
                    setEmail('')
                    setCompanyName('')
                    setMsg('')

                    setLoader(false);
                    setFormSubmitSucessMsg(true);

                    setTimeout(() => {
                        setFormSubmitSucessMsg(false);
                    }, 5000);

                    setEmailInvalid(false);
                    setPhoneInvalid(false);
                    setNameInvalid(false);
                }
              })
              .catch(e => {
                setLoader(false);

                setFormSubmitErrorMsg(true);

                    setTimeout(() => {
                        setFormSubmitErrorMsg(false);
                    }, 5000);
              })
    
            
        }else{
            setWarningActive(true)
        }


    }
    


  };








  return (
    <div className='contact-form' >

        <div className="container">

            <h2>Send us a message</h2>

            <form onSubmit={handleSubmit}>

                <div className="form-section">

                    <input type="text" name="name" id="name" placeholder='Name *'
                        value={name}
                        onChange={handleNameChange}

                        style={{
                            borderColor :
                            (nameInvalid === "red") ? 
                            'red' 
                            : (nameInvalid === "green") ?
                            'green'
                            :
                            '#C0C0C0'
                        }}

                    />

                    <input type="text" name="company-name" id="company-name" placeholder='Company Name *'
                        value={companyName}
                        onChange={handleCompanyNameChange}

                        style={{
                            borderColor :
                            (companyNameInvalid === "red") ? 
                            'red' 
                            : (companyNameInvalid === "green") ?
                            'green'
                            :
                            '#C0C0C0'
                        }}
                    />

                </div>

                <div className="form-section">

                    <input type="text" name="phone-no" id="phone-no" placeholder='Phone *'
                        maxLength={10}
                        value={phone}
                        onChange={handlePhoneChange}

                        style={{
                            borderColor :
                            (phoneInvalid === "red") ? 
                            'red' 
                            : (phoneInvalid === "green") ?
                            'green'
                            :
                            '#C0C0C0'
                        }}
                    />

                    <input type="email" name="email" id="email" placeholder='Email *' 
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                            borderColor :
                            (emailInvalid === "red") ? 
                            'red' 
                            : (emailInvalid === "green") ?
                            'green'
                            :
                            '#C0C0C0'
                        }}
                    />

                </div>

                <div className="form-section">

                    <textarea name=" message" id="message" placeholder='Message *'
                        value={msg}
                        onChange={handleMsgChange}

                        style={{
                            borderColor :
                            (msgInvalid === "red") ? 
                            'red' 
                            : (msgInvalid === "green") ?
                            'green'
                            :
                            '#C0C0C0'
                        }}
                    ></textarea>

                </div>
                        
                
                
                <button 
                type="submit"
                style={{
                    backgroundColor : sendBtnActive ? "#2FD549" : 'transparent'
                }}          
            >
                {
                    loader 
                    ?
                    <ClipLoader
                    color={"#fff"}
                    loading={true}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                /> 
                :
                "Send"
                }
                

            </button>

            <p
                style={{
                    backgroundColor : '#16F11E',
                    color : "#fff",
                    width : "100%",
                    textAlign : 'center',
                    padding : "10px",
                    marginTop : "5px",
                    display : formSubmitSucessMsg ? 'block' : 'none'
                }}
            >Thank You! We have received your message and will follow up shortly.</p>

                <p
                    style={{
                        backgroundColor : '#ffd5c9',
                        color : "red",
                        width : "100%",
                        textAlign : 'center',
                        padding : "10px",
                        marginTop : "5px",
                        display : formSubmitErrorMsg ? 'block' : 'none'
                    }}
                 >Sorry! Form not subbmitted, please try again.</p>

            <p
                style={{
                    backgroundColor : '#ffd5c9',
                    color : "red",
                    width : "100%",
                    textAlign : 'center',
                    padding : "3px",
                    marginTop : "4px",
                    opacity : warningActive ? 1 : 0
                }}
            >Please Fill Required Fields</p>

            </form>
        </div>

    </div>
  )
}

export default ContactFormMobile