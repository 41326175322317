import React, { useState } from 'react'
import dropDownArrow from '../assets/images/drop-down-arrow.webp'


function AccordianServicepage({mode, accordianList, openCard}) {

    const [activeIndex, setActiveIndex] = useState(null);
    const windowWidth = window.innerWidth;

    function toggleAccordian(index){
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }

    const columns = [[], [], []];

  // Distribute items across the three columns
  accordianList.forEach((item, index) => {
    columns[index % 3].push(item);
  });



  return (
    <div className='service-accordian-container'>
            {
                columns.map((item, index) => {
                    return (
                        <div className={"accordian-column accordian-column-"+index}  key={index}>

                            {
                                item.map((list,i) => {
                                    return (
                                        openCard
                                    ?
                                        <div className={"accordian-info " + mode} key={list.id} style={{minHeight : (windowWidth > 600) ? "200px" : "auto"}} >
                                            <div className="accordian-header">
                                                <p style={{fontWeight : 600}}>{list.heading}</p>
                                                {/* <img src={dropDownArrow} 
                                                    className="active" 
                                                    alt="drop-down-arrow" 
                                                /> */}
                                            </div>

                                            <div className="accordian-body active">
                                                <p className='smaller' dangerouslySetInnerHTML={{ __html: list.para }} />
                                                
                                            </div>
                                        </div>
                                        :
                                        <div className={"gradient accordian-info " + mode} key={list.id}  onClick={() => toggleAccordian(list.id)}>
                                            <div className="accordian-header">
                                                <p style={{fontWeight : 600}}>{list.heading}</p>
                                                <img src={dropDownArrow} 
                                                    className={(activeIndex === list.id) ? "active" : ""} 
                                                    alt="drop-down-arrow" 
                                                />
                                            </div>

                                            <div className={(activeIndex === list.id) ? "accordian-body active" : "accordian-body"}>
                                                <p className='smaller' dangerouslySetInnerHTML={{ __html: list.para }} />
                                                
                                            </div>
                                        </div>

                                        
                                    )

                                    
                                })
                            }
                            </div>
                    )
                })
            }

            

        


    </div>
  )
}

export default AccordianServicepage