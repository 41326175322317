import React, { useState } from 'react'
import arrow from '../assets/images/faq-accordian-arrow.webp'

function AccordianFaqs({active}) {


    const [activeIndex, setActiveIndex] = useState(null);
    

    const faqArray = [
        {
            id : 1,
            que : "How do you customize strategies for businesses ?",
            ans : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story",
        },
        {
            id : 2,
            que : "How do you measure campaign success ?",
            ans : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story",
        },

        {
            id : 3,
            que : "How do you ensure mobile compatibility ?",
            ans : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story",
        },

        {
            id : 4,
            que : "Can you integrate third-party tools and platforms into our website ?",
            ans : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story",
        },

        {
            id : 5,
            que : "What is your typical turnaround time for design projects?",
            ans : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story",
        },
    ]


    function toggleAccordian(index){
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }


  return (
    <div className='faq-container'>


        {
            faqArray.map((card, i) => {
                    return(
                    <div style={{ transitionDelay: i + "00ms"}} className={`faq-card ${active ? "active" : " "}`} onClick={() => toggleAccordian(i)} key={card.id}>
                        <div className="faq-header">
                            <img src={arrow} alt="arrow" />
                            <p>{card.que}</p>
                        </div>
            
                        <div className={(activeIndex === i) ? "faq-body active" : "faq-body"}>
                            <p className='smaller'>
                            {card.ans}
                            </p>
                        </div>
                    </div>
                    )
            })
        }

        

    </div>
  )
}

export default AccordianFaqs