import React from 'react'

function WhyPartnerWithUs() {
  return (
    <div className='partner-with-us'>
        <h2>Why Partner with Us?</h2>
        <p className='smaller'>Our comprehensive customer acquisition services are built on years of experience working with brands of all sizes, from startups to market leaders. We believe in creating sustainable growth by leveraging data, cutting-edge technology, and a personalised approach to meet your unique business needs.</p>
    </div>
  )
}

export default WhyPartnerWithUs