import React, { useRef } from 'react'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { Draggable } from 'gsap/Draggable'

gsap.registerPlugin(useGSAP, Draggable)
 
function Pendulum2() {


  const pendulumContainer = useRef(null)
  const pendulum = useRef(null)


  useGSAP(() => {

    Draggable.create(pendulum.current, {
      type : 'x',
      bounds : pendulumContainer.current
    })


  })

    
  


  return (
    <div className='pendulum-container' ref={pendulumContainer} >
      <div className="pendulum" >
        <div className="string first" ></div>
        <div className="ball first" ref={pendulum} ></div>
      </div>

      <div className="pendulum">
        <div className="string second"></div>
        <div className="ball second"></div>
      </div>

      <div className="pendulum">
        <div className="string third"></div>
        <div className="ball third"></div>
      </div>

      <div className="pendulum">
        <div className="string last"></div>
        <div className="ball last blast"></div>
      </div>
    </div>
  )
}

export default Pendulum2