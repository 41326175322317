import React from 'react'

function Graph() {
  return (
    <>
        <div className='graph'>
            <div className="circle blue">
                <p className='p1'>Marketing Services</p>

                <p className='p2'>Customer Acquisition Solutions                </p>
                        </div>
                        <div className="circle black">
                        <p className='p3'>Sales Services</p>
            </div>
        </div>
        <div className="graph-legends">
            <div className="legend first">
                <div className="legend-mark"></div>
                <h3>Marketing Services</h3>
            </div>
            <div className="legend second">
            <div className="legend-mark"></div>
            <h3>Sales Services</h3>
            </div>
        </div>
    </>
  )
}

export default Graph