import React, { useState } from 'react';
import openAccordian from "../assets/images/accordian-open.webp"
import closeAccordian from "../assets/images/accordian-close.webp"

function Accordian() {

    const [activeIndex, setActiveIndex] = useState(null);


    const accordianDataArray = [
        {
            id : 1,
            heading : "Our Story",
            headingText : "Nurturing Creativity, deploying technology, Amplifying Brands Igniting the Spark of Digital Brilliance",
            bodyText : "In a world where the digital landscape is ever-evolving, we've harnessed the energy of innovation to build a unique digital marketing agency. With a team of brilliant minds who are as creative as they are analytical, we've crafted success stories for businesses of all sizes. From dynamic startups to established enterprises, our journey has been one of discovery, learning, and trailblazing.",
        },

        {
            id : 2,
            heading : "Our Philosophy",
            headingText : "Digital Excellence with a Human Touch",
            bodyText : "What sets us apart is our belief in the human aspect of digital marketing. While technology is a powerful tool, we recognize that it's the people behind the screens that truly matter. We don't just create campaigns; we craft experiences that resonate with your audience on a personal level. Our philosophy centers around building meaningful connections, fostering engagement, and driving results that matter.",
        },

        {
            id : 3,
            heading : "Our Team",
            headingText : "The Dream Weavers Passion, Expertise, and Dedication in Every Pixel",
            bodyText : "Our team is a blend of creative wizards, analytical geniuses, and digital aficionados who are passionate about what they do. We thrive on challenges and are driven by the pursuit of excellence. Whether it's crafting compelling content, optimizing ad campaigns, or analyzing data insights, each member of our team plays a pivotal role in your digital success story.",
        },
        
    ]


    function toggleAccordian(index){
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }



    return accordianDataArray.map((card, i) => {

        return (
          
          <div className='accordian' onClick={() => toggleAccordian(i)} key={card.id}> 
              <div className="accordian-header">
      
                  <h2>{card.heading}</h2>
                  <p><span>{card.headingText}</span></p>
                  <img 
                    src={ (activeIndex === i) ?  closeAccordian : openAccordian} 
                    className={ (activeIndex === i) ?  "active" : ""} 
                    alt="toggle-accordian" 
                    />
      
              </div>
              <div className={(activeIndex === i) ? "accordian-body active" : "accordian-body"}>
                  <p className='smaller'>{card.bodyText}</p>
              </div>
          </div>
        )
    })
}

export default Accordian