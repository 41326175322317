import React, { useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PieChart() {

    const chartRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout
    }));

    const series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category"
    }));

    series.set('colors', am5.ColorSet.new(root, {
        colors : [
            am5.color(0x000000),
            am5.color(0x00051B),
            am5.color(0x000C40),
            am5.color(0x001466),
            am5.color(0x001B8C),
            am5.color(0x0022B0),
            am5.color(0x002AD8),
            am5.color(0x0031FF),
        ]
    }))

    series.data.setAll([
      { category: "Security", value: 10, },
      { category: "Automation", value: 10, },
      { category: "UI & UX", value: 10, },
      { category: "iOS & Android Apps", value: 10, },
      { category: "e-Commerce", value: 10, },
      { category: "Payment Gateway Integration & Maintenance", value: 10, },
      { category: "CRM Consultation", value: 10, },
      { category: "ERP Consultation", value: 10, },
    ]);
    
    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.slices.template.set("tooltipText", "{category}");

    root._logo.dispose();

    return () => {
      root.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} className='leg1'></div>;
}

export default PieChart