import React, { useEffect } from 'react'
import Pendulum from './Pendulum'
import Pendulum2 from './Pendulum2'

function ProgressSection() {


    const starCardArray = [
        {   
            id : 1,
            starFill : "#0031FF",
            p : "Our company's progress mirrors the pendulum's motion—steady, relentless, and forward-moving."
        },

        {
            id : 2,
            starFill : "#0029D5",
            p : "With each swing, propelled by innovation and dedication, we advance, guided by strategy and a commitment to excellence."
        },

        {
            id : 3,
            starFill : "#001FA4",
            p : "Like the pendulum's rhythm, our journey is deliberate and continuous, driving us toward success."
        },

        {
            id : 4,
            starFill : "#011777",
            p : "In this rhythm of progress, each swing brings us closer to our vision, each movement a testament to our resilience and ambition"
        },
    ]

    


  return (
    <section className='your-progress' >

        <section className="container">
        <div className="row row-1">
            <h2>How we swing the pendulum of progress</h2>

            <Pendulum />

            {/* <Pendulum2 /> */}
            
        </div>

        <div className="row row-2">

        {
            starCardArray.map(card => {
                return(

                <div className="star-card" key={card.id}>
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32" fill="none">
                    <path d="M17 0L21.5915 11.6785L34 16L21.5915 20.3215L17 32L12.4085 20.3215L0 16L12.4085 11.6785L17 0Z" fill={card.starFill}/>
                </svg>
                    <p style={{fontWeight : 600}}>
                    {card.p}
                    </p>
                </div>
                )
            })
        }



        </div>
        </section>
    </section>
  )
}

export default ProgressSection