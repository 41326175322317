import React, { useEffect, useRef, useState } from 'react';
import B from "../assets/images/Arrow.webp";
import V from "../assets/images/Line.webp";
import L from "../assets/images/rounder.webp";
import M from "../assets/images/setsquare.webp";
import N from "../assets/images/protractor.webp";
import O from "../assets/images/scale.webp";
import { Link } from 'react-router-dom';
import '../styles/morphed-styles.css'


function Home4() {

    const [counts, setCounts] = useState({
        customers: 0,
        revenue: 0,
        media: 0,
        projects: 0
    });

    const [customers, setcustomers] = useState(false)
    const [revenue, setrevenue] = useState(false)
    const [media, setmedia] = useState(false)
    const [projects, setprojects] = useState(false)


    

    

    const firstCountRef = useRef(null);
    const secondCountRef = useRef(null);
    const thirdCountRef = useRef(null);
    const fourthCountRef = useRef(null);

    // Function to animate counting up to a target value
    const animateValue = (start, end, duration, setStateFunc) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setStateFunc(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    };


    useEffect(() => {
            if(customers){
                animateValue(0, 100, 1000, (value) => setCounts((prevCounts) => ({ ...prevCounts, customers: value })));
            }
    },[customers])

    useEffect(() => {
        if(revenue){
            animateValue(0, 9000, 1000, (value) => setCounts((prevCounts) => ({ ...prevCounts, revenue: value })));
        }
    
        
},[revenue])

useEffect(() => {

    

    if(media){
        animateValue(0, 1250, 1000, (value) => setCounts((prevCounts) => ({ ...prevCounts, media: value })));
    }

    
},[
media,

])

useEffect(() => {


    if(projects){
        animateValue(0, 100, 1000, (value) => setCounts((prevCounts) => ({ ...prevCounts, projects: value })));
    }
},[

projects,        

])


    useEffect(() => {
      const handleScroll = () => {
        const trigger = (window.innerHeight / 5) * 4;
        console.log(trigger);


        if(firstCountRef.current.getBoundingClientRect().top < trigger){
            setcustomers(true)
        }

        if(secondCountRef.current.getBoundingClientRect().top < trigger){
            setrevenue(true)
             
        }

        if(thirdCountRef.current.getBoundingClientRect().top < trigger){
            setmedia(true)
             
        }

        if(fourthCountRef.current.getBoundingClientRect().top < trigger){
            setprojects(true)
             
        }


      };

      // Add scroll event listener
      document.addEventListener('scroll', handleScroll);

      // Cleanup function to remove event listener
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return(
        <div className='divStyle11'>
            <div className='secondDIv11'>
                <h1 className='spantag'>About Us</h1>

        <div className='containerStyle containerMobileStyle'>
            <div className="text-part txt1">
                <h2>A Team Made of <br /> Experts</h2>
            </div>
            <div className="text-part txt2" >
                <p className='wtext'>We are a team of experts who have been practising Digital Marketing since its Birth and have Grown with it. We are led by veterans who have created digital and tech businesses with following stats which depicts our expertise in the domain.</p>
                <br></br>
                <Link to={"/about"} style={{textDecoration : "none"}}>
                    <p className='txt3'>About Us&nbsp;&nbsp;&nbsp;<img src={B} alt='arrow' className='moveimg'></img>
                    
                    </p>
                </Link>
            </div>
        </div>
<br></br><br></br>
        <img src={V} className='txtimg' alt='line'></img>
        
        <div className='containerStyle'>
    
            <div className="text-part part1" >
                <div className="image-container imageContainer" >
                    <img className="img rounder img-2" src={L} alt='rounder'></img>
                </div>
            </div>
            <div className="text-part part2">
                <h2 style={{fontWeight : 400}}>Customer Acquisition</h2>
            </div>
            <div className="text-part part3" >
            <h2 className='count' ref={firstCountRef}>{counts.customers}K</h2>
            </div>
        </div>

        <img src={V} className='txtimg2' alt='line' ></img>
        
<div className='containerStyle'>
    
<div className="text-part part1">
            <div className="image-container imageContainer">
                    <img className='img set-square img-2' src={M} alt='set-square'></img>
            </div>
            </div>
            <div className="text-part part21">
                <h2 style={{fontWeight : 400}}>Revenue Generated</h2>
            </div>
            <div className="text-part part3">
            <h2 className='count' ref={secondCountRef}>{counts.revenue} Mn</h2>
            </div>
        </div>

        <img src={V}  className='txtimg3' alt='line'></img>
        
<div className='containerStyle'>
    
<div className="text-part part1" >
            <div className="image-container imageContainer" >
                <img  className='img' src={N} alt='protractor'></img>
            </div>
            </div>
            <div className="text-part part22" >
                <h2 style={{fontWeight : 400}}>Media Spend</h2>
            </div>
            <div className="text-part part3">
            <h2 className='count' ref={thirdCountRef}>{counts.media} Mn</h2>
            </div>
        </div>

        <img src={V} className='txtimg4' alt='line'></img>
        
<div className='containerStyle'>
    
<div className="text-part part1">
<div className="image-container imageContainer" >
                <img className='img' src={O} alt='ruler'></img>
            </div>
            </div>
            <div className="text-part part23" >
                <h2 style={{fontWeight : 400}}>Projects</h2>
            </div>
            <div className="text-part part3">
            <h2 className='count' ref={fourthCountRef} >{counts.projects}+</h2>
            </div>
        </div>

            </div>
        </div>

    );

}

export default Home4