import React, { useEffect, useRef } from 'react'

function Pendulum() {


  const sectionRef = useRef(null);
  const parentRef = useRef(null);

    useEffect(() => {
        const section = sectionRef.current;
        const parent = parentRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    parent.classList.add('animate');
                } 
                else {
                    parent.classList.remove('animate');
                }
            },
            {
                threshold: 1,
            }
        );

        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, []);
  


  return (
    <div className='pendulum-container' ref={parentRef} >
      <div className="pendulum" ref={sectionRef}>
        <div className="string first"></div>
        <div className="ball first" ></div>
      </div>

      <div className="pendulum">
        <div className="string second"></div>
        <div className="ball second"></div>
      </div>

      <div className="pendulum">
        <div className="string third"></div>
        <div className="ball third"></div>
      </div>

      <div className="pendulum">
        <div className="string last"></div>
        <div className="ball last blast"></div>
      </div>
    </div>
  )
}

export default Pendulum