import React, { useEffect, useState } from 'react'
import bulb from '../assets/images/bulb.png'
import bulbLight from '../assets/images/bulb-light-blue.png'
import bulbRays from '../assets/images/bulb-rays-blue.png'
import bulbLightGif from '../assets/images/bulbGif.gif'
import '../styles/contact.css'
import ContactInfo from '../components/ContactInfo'
import ContactForm2 from '../components/ContactForm2'
import ContactFormMobile from '../components/ContactFormMobile'
import ContactInfoMobile from '../components/ContactInfoMobile'


function Contact({menuHeight}) {

  const [imgChnage, setImageChange] = useState(false);

  const width = window.innerWidth;

  function handleBulb(){
    setImageChange(prev => !prev)
  }


  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = bulbLightGif;
    link.as = 'image';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = bulbLight;
    link.as = 'image';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="contact" style={{paddingTop : menuHeight + 50}}>

      <div className="container">
          <div className='header-container'>
              <div className="contact-header">
                <div className="imgcontainer">

                  {
                    imgChnage ? 
                      <div className='bulb-conatiner'

                        onMouseLeave={ (width > 990) ? handleBulb : null}
                        onClick={ (width < 990) ? handleBulb : null}
                        
                      >
                        <img  src={bulbRays} alt="bulb" className='bulb-ray' /> 
                        <img className="on" src={bulbLight} alt="bulb" /> 
                        <img className="off" src={ bulbLightGif} alt="bulb"  style={{opacity : 0}} />

                      </div>
                    :
                    <>
                    <div className='bulb-conatiner'

                        onMouseEnter={ (width > 990) ? handleBulb : null}
                        onClick={ (width < 990) ? handleBulb : null}

                      >
                      <img  src={bulbRays} alt="bulb" className='bulb-ray' style={{opacity : 0}} /> 
                      <img className="on" src={bulbLight} alt="bulb"  style={{opacity : 0}} /> 
                      <img className="off" src={ bulbLightGif} alt="bulb"   />

                      </div>
                        <img className="off" src={ bulbLightGif} alt="bulb"   />

                    </>
                  }

                      


                </div>
                <h1>Does it Click ? <br />
                Talk to us</h1>
              </div>
                
                    <ContactForm2 />

          </div>

          <div className="desktop-view">
            <ContactInfo />
          </div>
      </div>
      
      <div className="mobile-view">
        <ContactFormMobile />
        <ContactInfoMobile />
      </div>


    </div>
  )
}

export default Contact