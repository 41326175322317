import React, { useRef } from 'react'
import '../styles/service.css'
import CustomerAcquisition from '../components/CustomerAcquisition'
import Graph from '../components/Graph'
import GraphDetailedInfo from '../components/GraphDetailedInfo'
import CustomerAcquisitionBanner from '../components/CustomerAcquisitionBanner'
import whitePlus from '../assets/images/white-plus.svg'
import backgroundImage from '../assets/images/cust-aq-banner.png'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react';
import WhyPartnerWithUs from '../components/WhyPartnerWithUs'
import WhatWeOffer from '../components/WhatWeOffer'



function Services({menuHeight}) {

    const plusSvg = useRef(null)
    const path1 = useRef(null)
    const path2 = useRef(null)
    const path3 = useRef(null)

    const windiwWidth = window.innerWidth;


    // header Customer Aqusition Data

    const customerAcquisition = {
        heading : "Customer Acquisition",
        subHeading : "Marketing <span>+</span> Sales",
        para : "We understand that customer acquisition is the lifeblood of any business. With a continuously evolving digital landscape, it’s critical to have a marketing strategy that not only captures consumer attention but turns leads into customers. We specialise in consulting and managing end-to-end customer acquisition solutions that drive sustainable growth in the highly competitive market",
    }


    // Accordian Data Passing

    const mqlData = {
            heading : "Marketing Services",
            para : ``,

            accordianList : [
                {
                    id : 1,
                    heading : "Market Research & Audience Analysis",
                    para : `Identify target markets, define buyer personas, and understand audience behaviours to craft effective acquisition strategies.`
                },
                {
                    id : 2,
                    heading : "Search Engine Optimization (SEO)",
                    para : `Optimise your website and content for search engines to improve organic visibility and drive quality traffic that converts.`
                },
                {
                    id : 3,
                    heading : "Content Marketing",
                    para : `Develop engaging content that resonates with your audience and drives them through the buyer’s journey, from awareness to decision.`
                },
                {
                    id : 4,
                    heading : "Paid Advertising (PPC & Social Ads)",
                    para : `Run targeted advertising campaigns on platforms like Google, Facebook, LinkedIn, and Instagram to attract potential customers quickly and efficiently.`
                },
                {
                    id : 5,
                    heading : "Email Marketing",
                    para : `
                        Design and implement email campaigns to nurture leads, build relationships, and convert prospects into loyal customers.
                    `
                },
                {
                    id : 6,
                    heading : "Conversion Rate Optimization (CRO)",
                    para : `
                        Analyse and optimise your website and landing pages to improve user experience and maximise conversion rates.
                    `
                },
                {
                    id : 7,
                    heading : "Social Media Marketing",
                    para : `Build your brand’s presence on social media platforms to connect with your audience, boost engagement, and drive traffic to your site.`
                },
                {
                    id : 8,
                    heading : "Marketing Automation",
                    para : `Use automation tools to streamline lead nurturing processes, ensuring timely and personalised communication with potential customers.`
                },
                {
                    id : 9,
                    heading : "Analytics & Reporting",
                    para : `Continuously monitor campaign performance, analyse data, and provide transparent reports to measure ROI and make data-driven decisions for future strategies.`
                },
            ]
        }


        const sqlData = {
            heading : "Sales (Inside/Online) Services",
            para : `After successfully generating leads for your business, the next step is to convert them into paying customers. Digital customer acquisition is a meticulous process of churning volumes of leads and closely tracking each of them to drive conversion. Prodmax in house sales specialist team & CRM facilitates a gripping control of the entire lead journey in the sales funnel to deliver maximum sales on the lead generation investment. Our Inside Sales Services are designed to nurture leads, engage prospects, and close deals efficiently. We focus on creating a seamless, data-driven sales process that shortens your sales cycle and maximises your revenue.`,

            accordianList : [
                {
                    id : 1,
                    heading : "Lead Qualification & Segmentation",
                    para : ` 
                        Once leads are generated, we help qualify them based on their level of interest, fit, and likelihood to convert. Use of lead segmentation and lead scoring model built in our CRM to group leads based on criteria such as industry, company size, buyer intent, and more to personalize sales approaches and prioritize high-potential prospects and focus sales efforts on the most promising opportunities.    
                    `
                },
                {
                    id : 2,
                    heading : "Outbound Sales Outreach",
                    para : ` 
                        Our team engages in outbound sales activities and email outreach to warm up and nurture database into leads via emails, cold phone calls, using tailored sales scripts to build rapport.    
                    `
                },
                {
                    id : 3,
                    heading : "Follow-Up & Lead Nurturing",
                    para : `
                        Not all leads convert immediately, and our inside sales team ensures consistent follow-ups to keep prospects engaged by implementing structured follow-up sequences via calls, emails, and social touchpoints to ensure no lead falls through the cracks.
                    `
                },
                {
                    id : 4,
                    heading : "Product Demos & Virtual Meetings",
                    para : `
                        Our team can schedule and conduct product demonstrations or virtual meetings with qualified leads to deliver live, personalised product demos to showcase your offering and address specific pain points and engage prospects via virtual meetings. 
                    `
                },
                {
                    id : 5,
                    heading : "Appointment Booking",
                    para : ` 
                        Once the leads are qualified as per the client filtering criteria our team can drive walk-ins, pass of the lead to client sales team, book appointments to make personal visit at clients location.
                    `
                },
                {
                    id : 6,
                    heading : "Sales Pipeline Management",
                    para : `
                        Efficient pipeline management is crucial to ensure no opportunity is missed. Our team keep a tarck of active deals ensuring timely follow ups and objection handling as and when required Monitor lead movement through the sales funnel, ensuring each lead progresses smoothly from interest to closing.
                    `
                },
                {
                    id : 7,
                    heading : "Sales Closing Support",
                    para : `
                        The final step is converting leads into customers, and our team ensures a smooth and professional closing process by draft and delivering personalised proposals and contracts tailored to the lead’s needs and guide the prospects through the final stages of the sales process, ensuring all questions are answered, terms are clear, and contracts are signed.
                    `
                },
                {
                    id : 8,
                    heading : "Customer Onboarding Assistance",
                    para : `
                        Once a deal is closed, our inside sales team can assist with the customer onboarding process to ensure a smooth transition and ensure that clients receive all necessary resources and support to get started with your product or service. We also offer post-sales support as a point of contact for any post-sales inquiries, ensuring customer satisfaction and a positive experience from the beginning.
                    `
                },
            ]
        }




        useGSAP(() => {
            gsap.from(plusSvg.current, {
                translateY : (windiwWidth > 990) ? "-86vh" : ( windiwWidth > 600) ? "-50vh" : "-65vh",
                translateX : (windiwWidth > 990) ? "34.5vw" : ( windiwWidth > 600) ? "-25vw" : "-14vw",

            scrollTrigger : { 
                scroller : 'body',
                start : 'top 0%',
                end :  (windiwWidth > 990) ? "top -100%" : ( windiwWidth > 600) ? "top -50%" : "top -50%",
                scrub : 2,                
            },
            })


            gsap.from(path1.current , {
                attr : {
                    fill : "#fff"
                },
                
                scrollTrigger : { 
                    scroller : 'body',
                    start : 'top 0%',
                    end : "top -100%",
                    scrub : 2,                
                },
    
            })
    
            gsap.from(path2.current , {
                attr : {
                    fill : "#fff"
                },
                scrollTrigger : { 
                    scroller : 'body',
                    start : 'top 0%',
                    end : "top -100%",
                    scrub : 2,                
                },
            })

            gsap.from(path3.current , {
                attr : {
                    fill : "#fff"
                },
                scrollTrigger : {
                    scroller : 'body',
                    start : 'top 0%',
                    end : "top -100%",
                    scrub : 2,                
                },
            })

        })

        
    


  return (

    <>
    <CustomerAcquisitionBanner heading={"Customer Acquisition"} image={whitePlus} backgroundImage={backgroundImage}  />
    <div className="container">
        <section className='service' style={{paddingTop : (windiwWidth > 990)? menuHeight + 50 : null}}>   
            <svg 
                viewBox="0 0 184 189" 
                fill="none" 
                className='svg'
                width={151.6}
                height={155.6}
                ref={plusSvg}
            >
                <g>
                    <path ref={path1} d="M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67" fill="#000"/>

                    <path ref={path2} d="M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78" fill="#000"/>

                    <path ref={path3} d="M61,0 L65,0 L68,0 L71,0 L74,0 L78,0 L81,0 L84,0 L87,0 L88,0 L88,0 L89,0 L89,1 L90,1 L90,2 L91,2 L91,3 L91,3 L91,4 L91,8 L91,11 L91,14 L91,17 L91,21 L91,24 L91,27 L91,30 L91,34 L91,37 L91,40 L91,43 L91,46 L91,49 L91,52 L91,55.7037 L91,56 L91,57 L91,58 L90,58 L90,59 L89,59 L89,59 L88,59 L88,60 L87,60 L84,60 L81,60 L78,60 L74,60 L71,60 L68,60 L65,60 L61,59.7037 L61,59.7037 L60,60 L60,59 L59,59 L59,59 L58,59 L58,58 L57,58 L57,57 L57,56 L57,56 L57,50 L57,47 L57,43 L57,37 L57,34 L57,30 L57,24 L57,21 L57,17 L57,11 L57,8 L57,4 L57,3 L57,3 L57,2 L58,2 L58,1 L59,1 L59,0 L60,0 L60,0 L61,0" fill="#0031FF"/>
                </g>
            </svg>

            <CustomerAcquisition data={customerAcquisition} />

            <WhyPartnerWithUs />

        </section>
        {/* <Graph /> */}
    </div>
            <WhatWeOffer />

        <GraphDetailedInfo mode={"dark"} dataObj={mqlData} />
        <GraphDetailedInfo mode={"light"} dataObj={sqlData}/>
    </>

  )
}

export default Services