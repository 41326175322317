import React from 'react'
import pune from '../assets/images/pune icon-12 1.webp';
import mumbai from '../assets/images/mumbai-12 1.webp';
import chennai from '../assets/images/chennai.webp';
import mailImage from '../assets/images/email (1).webp';
import phoneImage from '../assets/images/phone-call (1).webp';


function ContactInfo() {
  return (
    <div className='contact-info'>
        <h3 >Contact Us</h3>


        <div className="Email-container">

            <div className="email">                
                <img src={mailImage} alt="email" />
                <a href="mailto:info@prodmax.in"><h3 className='add'>info@prodmax.in</h3></a>
            </div>

            <div className="email">                
                <img src={phoneImage} alt="email" />
                <a href="tel:+91 9967636592"><h3 className='add'>+91 9967636592</h3></a>
            </div>

        </div>

        <div className="info-container">

            <div className="info">                
                <img src={pune} alt="city-pune" />
                <h3>Pune</h3>
            </div>

            <div className="info">                
                <img src={mumbai} alt="city-mumbai" />
                <h3>Mumbai</h3>
            </div>

            <div className="info">                
                <img src={chennai} alt="city-chennai" />
                <h3>Chennai</h3>
            </div>
        </div>

        


    </div>
  )
}

export default ContactInfo